import { Grid,Box } from '@mui/material';
import './App.css';

function App() {
  return (<>


    <div className="content">
    <div style={{marginBottom:'5rem',marginTop:'1rem',width:'100%'}} className="btn__align" >
        <div className="header-menu">
      <button  className="islink">
                    <span style={{fontSize: '12px'}}>CONNECT YOUR</span><br></br>
                    <span style={{fontSize:' 14px'}}>WALLET</span>
      </button>
      </div>
  
    </div>
    <div className="presell">
      
    <div className="window" >
    <div className="title-bar ui-draggable-handle">
                            <div className="title-bar-text">
                                GIF-PreSell.exe
                            </div>
                            <div className="title-bar-controls">
                                <button className="btn-close">x</button>
                            </div>
    </div>
    <div className="window-body">
                        <div className="presell-items">
                        <Box sx={{flexGrow: 1 }}>
    <Grid alignItems="center" container>
      <Grid item xs={12} md={8}>
      <div className="presell-title">
                                <p>
                                    10,000 unique MAX's <br></br> who need to be save.
                                </p>
                                <p className="text">
                                    Save Max is a collection of 10,000 unique <br></br> hand drown randomly generated MAX's
                                </p>
             
        </div>
      </Grid>

      <Grid style={{textAlign:'center'}} item xs={12} md={4}>
      <div className="presell-product">
                                <div className="img-border">
                                    <div className="img-content">
                                        <img src="https://save-max.com/assets/img/GIF%20SAVEMAX.gif" alt=""/>
                                    </div>
                                </div>
                                <div className="product-text">
                                    500 PRE SALES <br></br> &lt;-- TICKET 1 Sol
                                </div>
      </div>
      </Grid>
      <Grid item xs={12} md={6}>
      <div className="presell-title">
      <a href="#">
                                    MINT YOURS !
      </a>
      </div>
      </Grid>
    </Grid>
  </Box>
                        </div>
                    </div>

    </div>
    </div>    </div>

  </>);
}

export default App;
